


















































import { defineComponent, toRefs } from '@vue/composition-api'
import useNews from '../hooks/use-news'

export default defineComponent({
  name: 'DeskNew',
  components: {
    AsyncList: () => import('./async/list-contain.vue')
  },
  setup() {
    const { state, parseTime, handleClick, handleSelect, handleSetShow } =
      useNews()

    return {
      ...toRefs(state),
      parseTime,
      handleClick,
      handleSelect,
      handleSetShow
    }
  }
})
